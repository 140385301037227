
import {defineComponent} from "vue";


import List from "@/components/reports/hotel/hotel-in-house-report/List.vue";

export default defineComponent({
  name: "hotel-in-house-report",
  components: {
    List,
  },
  setup() {
    return {

    };
  },
});
